export const m = {
  systemsettings: "System settings", //系统设置
  menumanagement: "Menu Management", //菜单管理
  rolemanagement: "Role Management", //角色管理
  administratormanagement: "Administrator Management", //管理员
  hospiatalmanagement: "Hospital Management", //医院管理
  patientinformation: "Patient Information", //病人信息
  patientmanagement: "Patient Management",
  option2: "Option2",
  option3: "Option3",
  othermanagement: "Other Management", //其他设置
  option1: "Option1",
  homepage: "Home page", //首页
  welecom: "Welecom",
  add: "Add", //添加
  name: "Name", //名称
  icon: "Icon", //图标
  type: "Type", //类型
  operation: "Operation", //操作
  catalogue: "Catalogue", //目录
  edit: "Edit", //编辑
  delete: "Delete", //删除
  view: "View",
  superior: "Superior", //上级
  cataloguename: "Cataloguename", //目录名称
  state: "State", //状态
  menu: "Menu", //菜单
  determination: "Determination", //确定
  reset: "Reset", //重置,
  psaaa: "Please select",
  administrator: "Administrator", //管理员
  doctor: "Doctor", //医生
  enable: "Enable", //启用
  rolename: "Role Name", //角色名称
  menupermissions: "Permissions", //菜单权限
  userid: "User ID", //用户id
  role: "Role", //角色
  username: "User Name", //用户名
  examine: "Examine", //查看
  jobnumber: "Jobnumber", //工作编号
  doctorname: "Doctorname", //医生名称
  password: "Password", //密码
  confirmpasdsword: "Confirm password", //确认密码
  nurse: "Nurse Role",
  patient: "Patient Role",
  patientid: "Patient No", //病人ID
  patientidname: "Patient Name", //病人姓名
  intreatment: "In treatment", //就诊中
  personalinformation: "Personal Information", //病人信息
  familyname: "Family Name:", //姓
  firstname: "First Name:", //第一个名字
  middlename: "Middle Name", //中名
  gender: "Gender:", //性别
  race: "Race", //民族
  slo: "select your option", //请选择
  dob: "Date of Birth:", //选择日期
  pd: "Physical Date(BioData)", //物理数据
  utms: "Use the metric system", //使用公制
  utis: "Use the Imperial system", //使用帝国制
  ft: "Ft",
  lb: "Lb",
  kg: "Kg",
  meter: "Meter",
  continus: "Continus", //下一步
  previouspage: "Previous page", //上一页
  pefn: "Please enter Family name", //请输入姓
  pefirstn: "Please enter First name",
  pemn: "Please enter Middle name",
  poccu: "Please enter your occupation",
  female: "Female", //女性
  male: "Male", //男性,
  africanamerican: "African-American", //费裔美国人
  asianamerican: "Asian-American", //亚裔美国人
  caucasian: "Caucasian", //白种人
  hispanic: "Hispanic", //西班牙
  others: "Others", //其他
  hospitalinformation: "Hospital Information", //医院信息
  hospitalnumber: "Hospital Number", //医院编号
  hospitalname: "Hospital Name", //医院名字
  doctorinformation: "Doctorinformation", //医生信息
  doctornumber: "Doctornumber", //医生编号
  pethn: "Please enter the hospital number", //请输入医院编号
  pethname: "Please enter the hospital name", //请输入医院名字
  pedn: "Please enter doctor number", //请输入医生编号
  petdname: "Please enter the doctor's name", //请输入医生姓名
  dychohbpdwaotad: "Do you currently have or have been previously diagnosed with any of the above diseases", //您目前或以前是否被诊断患有上述任何疾病
  diabetes: "Diabetes", //糖尿病
  neuropathy: "Neuropathy", //神经病
  hypertension: "Hypertension", //高血压
  implantationofpacemaker: "Implantation of pacemaker", //植入起搏器
  hepatopathy: "hepatopathy", //肝病
  kidneydisease: "kidney disease", //肾脏疾病
  nicotineuse: "Nicotine use", //尼古丁使用
  dialysistreatment: "Dialysis treatment", //透析治疗
  novelcoronavirus: "2019 novel coronavirus", //2019新冠病毒
  miocardialinfarction: "miocardial infarction", //心肌梗塞
  pvd: "Peripheral vascular disease", //外周血管疾病
  ptcaos: "Percutaneous transluminal coronary angioplasty or stenting", //经皮腔内冠状动脉成形术或支架
  cerebrovascularaccident: "Cerebrovascular accident", //脑血管意外
  congestiveheartfailure: "Congestive heart failure", //充血性心衰
  Coronaryarterybypassgrafting: "Coronary artery bypass grafting", //冠状动脉旁路移植术
  listcurrentdrugs: "List current drugs:", //列出目前药物
  otherhealthtestresults: "Other health test results:", //其他健康测试结果
  supplement: "Supplement:", //补充意见
  reasonforvisit: "Reason for visit:", //就诊原因
  accountnumber: "Account number", //账号
  submit: "Submit", //提交
  globaladmin: "Global Administrator Role",
  hospitaladmin: "Hospital Admin Role",
  doctorrole: "Doctor Role",
  accountmanagement: "Account Management",
  globaladminaccount: "Global Administrator Account",
  hospitaladminaccount: "Hospital Admin Account",
  doctoraccount: "Doctor Account",
  devicemanagement: "Device Management",
  testresult: "Test Result",
  dpht: "Patient History Testing",
  uef: "Upload File",
  historymedical: "Medical History",
  email: "Email",
  ekts: "Enter keywords to search",
  deviceid: "Device ID",
  devicename: "Device Technology",
  portnumber: "Device Number",
  switchaccount: "Switch account",
  exitlogin: "Exit login",
  uaf: "Upload Attachment File",
  clickupload: "Click Upload",
  date: "Test Time",
  results: "Results",
  clicktoview: "Click To View",
  details: "Details",
  parametername: "Parameter Name",
  parameterrange: "Parameter Range",
  parametervalue: "Parameter Value",
  arameterdescription: "Parameter Description",
  parameterresults: "Parameter Results",
  address: "Address",
  dateofbirth: "Date of birth",
  dateofbirth1: "Please enter your date of birth",
  hospitaladdress: "Hospital address",
  petha: "Please enter the hospital address",
  // meta:{role:'Role'},
  viewdetails: "View Details",
  down: "Download",
  downall: "Download All",
  historytest: "History Test",
  myaccount: "My Account",
  aboutus: "About us",
  recentpatient: "Recent patient",
  accountid: "Account ID",
  hospitalid: "Hospital ID",
  doctorid: "Doctor ID",
  morning: "Good Morning ",
  payment: "Payment",
  subscription: "Subscription",
  linechart: "Line chart",
  Pemailadd: "Please enter email address",
  switchcharts: "Switch charts",
  Switchingnumber: "Switching number",
  next: "Next",
  testmodule: "Test Module",
  occupation: "Patient Occupation:",
  edulevel: "Patient Education Level:",
  pcountry: "Patient Country Name:",
  pcity: "Patient City Name:",
  pstate: "Patient State Name:",
  pethic: "Ethic Origin:",
  paddres1: "Patient Address Line1:",
  paddres2: "Patient Address Line2:",
  ppostal: "Patient Postal Code:",
  phisid: "medical_history_id",
  bioid: "biodata_id",
  all: "All examples",
  upload: "Upload",
  features: "Features",
  return: "Return",
};
