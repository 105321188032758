import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
// import shouye from "@/assets/img/Group 39.png";
// import shouye1 from "@/assets/img/Group 3.png";
// import patient from "@/assets/img/patient.png";
// import patient1 from "@/assets/img/patient1.png";

// import exam from "@/assets/img/exam.png";
// import exam1 from "@/assets/img/exam1.png";
// import role from '@/assets/img/Avatar.png'
// import state from '../store'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/",
        redirect: "/index",
      },
      // 首页 home page
      {
        path: "/index",
        name: "index",
        // meta: {
        //   title: "Home",
        //   icon: shouye,
        //   icon1: shouye1,
        // },
        meta: {
          title: "Home",
          // keepAlive: true,
        },
        component: () => import("../layout/index/IndexView.vue"),
      },
      // 角色 role management
      {
        path: "role",
        name: "role",
        meta: { title: "Role Management" },
        component: () => import("@/layout/role/IndexView.vue"),
      },
      // 添加角色 role add
      {
        path: "roleadd",
        name: "role.roleadd",
        meta: { title: "Add Role" },
        component: () => import("@/layout/role/RoleAdd.vue"),
      },

      // 病人管理 patient management
      {
        path: "patient",
        name: "patient",
        meta: {
          title: "Patient Management",
          // keepAlive: true,
        },
        component: () => import("@/layout/Personal/IndexView.vue"),
      },

      // 添加病人页1 patientadd
      {
        path: "patientadd",
        name: "patient.patientadd",
        meta: { title: "Add Patient" },
        component: () => import("@/layout/Personal/PatientAdd.vue"),
      },

      {
        path: "patientview",
        name: "patient.patientview",
        meta: { title: "Patient View" },
        component: () => import("@/layout/Personal/PatientView.vue"),
      },
      {
        path: "patientreport",
        name: "patient.patientreport",
        meta: { title: "Patient Report" },
        component: () => import("@/layout/Personal/PatientReport.vue"),
      },
      {
        path: "clinicaldata",
        name: "exams.clinicaldata",
        meta: { title: "Clinical Data" },
        component: () => import("@/layout/Personal/ClinicalData.vue"),
      },
      {
        path: "addclinical",
        name: "clinicaldata.addclinical",
        meta: { title: "Add Clinical Data" },
        component: () => import("@/layout/Personal/AddClinicalData.vue"),
      },
      // 病人测试结果 test result
      {
        path: "patientexamslist",
        name: "patient.patientexamslist",
        meta: { title: "Exams" },
        component: () => import("@/layout/PatientExams/PatientExamsList.vue"),
      },

      // 历史测试 history test
      {
        path: "historyexamcharts",
        name: "patient.historyexamcharts",
        meta: { title: "Results Progress" },
        component: () => import("@/layout/PatientExams/HistoryExamCharts.vue"),
      },
      {
        path: "exams",
        name: "exams",
        meta: {
          title: "Exam Management",
          // keepAlive: true,
        },
        component: () => import("@/layout/Exam/IndexView.vue"),
      },
      {
        path: "addexams",
        name: "exams.addexams",
        meta: { title: "Order Exams" },
        component: () => import("@/layout/Exam/AddExams.vue"),
      },

      {
        path: "examview",
        name: "exams.examview",
        meta: { title: "View Results " },
        component: () => import("@/layout/Exam/ExamsView.vue"),
      },
      {
        path: "MedicalReferring",
        name: "MedicalReferring",
        meta: {
          title: "Referring Medical",
          // keepAlive: true,
        },
        component: () => import("@/layout/MedicalReferring/IndexView.vue"),
      },
      {
        path: "addReferring",
        name: "MedicalReferring.addReferring",
        meta: { title: "Add Referring MD" },
        component: () => import("@/layout/MedicalReferring/AddReferring.vue"),
      },
      {
        path: "staff",
        name: "staff",
        meta: {
          title: "Staff Management",
          // keepAlive: true,
        },
        component: () => import("@/layout/Staff/StaffView.vue"),
      },
      {
        path: "facility",
        name: "facility",
        meta: {
          title: "Facility Management",
          // keepAlive: true,
        },
        component: () => import("@/layout/Facility/FacilityView.vue"),
      },
      {
        path: "addStaffView",
        name: "StaffView.addStaffView",
        meta: { title: "Add Staff" },
        component: () => import("@/layout/Staff/AddStaffView.vue"),
      },
      {
        path: "addFacilityView",
        name: "FacilityView.addFacilityView",
        meta: { title: "Add Facility" },
        component: () => import("@/layout/Facility/AddFacilityView.vue"),
      },
    ],
  },
  // 登录 loginaddMedical
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },

  // 页面丢失 pagemiss
  {
    path: "/*",
    name: "pagemiss",
    component: () => import(/* webpackChunkName: "about" */ "../views/PageMiss.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});
// import store from "../store" --++++++           * ****
// import versionTood from '@/common/versionUpdate.js'

router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    let token = localStorage.getItem("token");
    if (token) {
      next("/");
    }
    next();
  } else {
    let token = localStorage.getItem("token");
    if (token === null || token === "") {
      next("/login");
    } else {
      next();
    }
  }
  const examId = localStorage.getItem("examId");
  if (to.fullPath === "/clinicaldata" && examId) {
    next("/clinicaldata?examid=" + examId);
  }
  document.title = "QuanCardio";
  next();
});
export default router;
