<template>
  <div class="el-aside">
    <el-row
      type="flex"
      justify="center"
    >
      <el-col :span="22">
        <div class="logo">
          <el-image :src="require('@/assets/img/Group 1.png')" />
        </div>
        <el-menu
          class="menus"
          :default-active="activeIndex"
          background-color="#f7f9fa"
          actve-background-color="#EC1C24"
          text-color="#333"
          active-text-color="#fff"
          router
        >
          <el-menu-item
            v-for="item in menulist"
            id="menuitems"
            :key="item.index"
            class="menuitem"
            :index="item.path"
          >
            <img
              style="margin-right: 20px;"
              :src="activeIndex===item.path? item.icon1:item.icon"
              alt
            >
            <span
              slot="title"
              class="Sidebar_title"
            >{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        aside_height: 0,
        menulist: [],
        activeIndex: "/index"
      };
    },

    watch: {
      $route() {
        this.activeIndex = this.$route.path;
      }
    },
    created() {
      localStorage.getItem("menulist") &&
        (this.menulist = JSON.parse(localStorage.getItem("menulist")));
      this.activeIndex = this.$route.path;
    },
    methods: {
    }
  };
</script>

<style scoped>
.logo {
    margin: 1.25rem 0 2rem 0;
    display: flex;
    justify-content: center;

}

.el-aside {
    background-color: #F7F9FA;
    color: #333;
    text-align: left;
    height: 100%;
}



.el-submenu__title i {
    color: #333 !important;
}

.el-menu-item {
    height: auto;
    background-color: #f7f9fa !important;
    border-radius: 16px;
    /* line-height: 64px; */
    margin-top: 10px;
}

a {
    text-decoration: none;
}

.asidview {
    /* width: 336px !important; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.el-menu {
    border-right: none;
    /* padding-left: 20px !important; */
}

.el-menu-item.is-active {
    background-color: #ec1c24 !important;
    color: #fff;
}
</style>
