<template>
  <div class>
    <el-row
      type="flex"
      justify="center"
    >
      <el-col :span="4">
        <aside-view />
      </el-col>
      <el-col :span="20">
        <el-header>
          <header-view />
        </el-header>
        <div
          class="main"
          :style="{minHeight:image_height+'px'}"
        >
          <router-view />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
  import AsideView from "../components/AsideView.vue";
  import HeaderView from "../components/HeaderView.vue";

  export default {
    name: "HomeView",
    components: {
      AsideView,
      HeaderView
    },
    data(){
      return{
        image_height: "",
        screenWidth:""
      }
    },
    mounted() {
      // 首次加载时,初始化高度
      this.screenWidth = window.innerHeight;
      this.image_height = window.innerHeight-135;
      // 窗口大小发生改变
      window.onresize = () => {
        this.screenWidth = window.innerWidth;
        this.image_height = window.innerHeight-135;
      };
    
    },

  };
</script>

<style scoped>
.el-header {
  height: 5.44rem !important;
  background-color: #fff;
  color: white;
  text-align: center;
  border-bottom: 1px solid #e1e8ec;
}

.main {
  background-color: #fff;
  color: #333;
  overflow: hidden;
  padding: 24px;
}
</style>
