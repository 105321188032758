import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueI18n from "vue-i18n";
import locale from "element-ui/lib/locale/lang/en";
import axios from "axios";
import Enum from "vue-enum";
import examstatus from "@/views/LoginView.vue";
import "./assets/element-variables.scss";
import "@/assets/font/fonts.css";
Vue.use(ElementUI, { locale });
Vue.use(VueI18n);

Vue.use(Enum, examstatus);

Vue.prototype.$globaltime = 3000;

const VUE_APP_VERSION = require("../package.json").version;
const vers = window.localStorage.getItem("appVersion");
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload(true);
}

const i18n = new VueI18n({
  locale: "en-CN",
  messages: {
    "zh-CN": require("./lang/zh"),
    "en-US": require("./lang/en"),
  },
  silentTranslationWarn: true,
});

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,

  // axios,
  render: (h) => h(App),
}).$mount("#app");
