<template>
  <div>
    <el-row>
      <el-col :span="16">
        <!-- <el-image
          :style="{ width:100+'%', height:image_height+'px', }"
          :src="require('@/assets/img/Mask group.png')"
          :fit="fill"
        ></el-image>-->

        <el-image
          :style="{ width:100+'%', height:image_height+'px', }"
          :src="require('@/assets/img/Mask group.png')"
        />
      </el-col>
      <el-col :span="8">
        <el-row
          type="flex"
          class="row-bg"
          justify="space-around"
        >
          <el-col
            :span="8"
            class="logo"
          >
            <el-image :src="require('@/assets/img/Group 1.png')" />
            <!-- <el-image :src="require('@/assets/img/Group 1.png')" :fit="cover"></el->image -->
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
        >
          <el-col :span="20">
            <div class="welcome-title">
              <h2>Welcome to login</h2>
            </div>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              class="demo-ruleForm"
            >
              <p
                class="Content_title"
                style="margin-bottom:16px;"
              >
                Email
              </p>
              <el-form-item prop="loginEmail">
                <div class="login-form">
                  <el-input
                    id="username"
                    v-model="ruleForm.loginEmail"
                    placeholder="Please enter your email address"
                  />
                </div>
              </el-form-item>
              <p
                class="Content_title"
                style="margin-bottom:16px;"
              >
                Password
              </p>
              <el-form-item prop="loginPassword">
                <div class="login-form">
                  <el-input
                    id="password"
                    v-model="ruleForm.loginPassword"
                    show-password
                    type="password"
                    autocomplete="off"
                    placeholder="Please enter your login password"
                  />
                </div>
              </el-form-item>

              <el-form-item>
                <el-button
                  class="btn btn-primary"
                  type="primary"
                  @click="submitForm('ruleForm')"
                >
                  Login
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { Login, enmu } from "@/request/login";
  import router from "@/router";
  import store from "../store";

  export default {
    data() {
      return {
        image_height: 0,
        url: "",
        ruleForm: {
          loginEmail: "",
          loginPassword: ""
        },
        rules: {
          loginEmail: [
            {
              required: true,
              message: "Please enter your Email",
              trigger: "blur"
            }
          ],
          loginPassword: [
            {
              required: true,
              message: "Please enter your Password",
              trigger: "change"
            }
          ]
        }
      };
    },
    mounted() {
      // 首次加载时,初始化高度
      this.screenWidth = window.innerHeight;
      this.image_height = window.innerHeight;
      // 窗口大小发生改变
      window.onresize = () => {
        this.screenWidth = window.innerWidth;
        this.image_height = window.innerHeight;
      };
    },

    methods: {
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            Login(this.ruleForm)
              .then(res => {
                if (res.data.category == 1) {
                  store.commit("set_token", res.data.data.token);
                  sessionStorage.setItem(
                    "token",
                    JSON.stringify(res.data.data.token)
                  );
                  // store.commit("set_token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJIb3NwaXRhbElkIjoiM2YxMTdiZTQtNGM5Zi00NGQ1LTliODMtOWI4MGU3ZGFkNWYyIiwiaWQiOiIxMCIsImV4cCI6MTcxMjY1MDk0NywiZW1haWwiOiJhZG1pbiJ9.y2kW8w3RE2yd7BN5BY-jsH_e9l3_hjenQ4UrwJEL6WE");

                  //  sessionStorage.setItem(
                  //   "token",
                  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJIb3NwaXRhbElkIjoiM2YxMTdiZTQtNGM5Zi00NGQ1LTliODMtOWI4MGU3ZGFkNWYyIiwiaWQiOiIxMCIsImV4cCI6MTcxMjY1MDk0NywiZW1haWwiOiJhZG1pbiJ9.y2kW8w3RE2yd7BN5BY-jsH_e9l3_hjenQ4UrwJEL6WE"
                  // );
                  localStorage.setItem("menulist",JSON.stringify(res.data.data.menulist));
                  enmu().then(res => {
                    localStorage.setItem("enmu", JSON.stringify(res.data.data));
                  });
                  router.push("/index");
                } else {
                  return;
                }
              })
              .catch(() => {});
          } else {
            return false;
          }
        });
      }
    }
  };
</script>

<style scoped>
.box {
  height: 1080px;
}

.logo {
  margin-top: 48px;
}

.welcome-title {
  padding-top: 20%;
}

.welcome-title h2 {
  font-size: 40px;
  /* font-family: Google Sans Display; */
  font-weight: 700;
  line-height: 50px;
  color: #1b2126;
}

.login-form {
  margin: auto;
  display: flex;
  justify-content: center;
}

.login-form p {
  font-family: Google Sans Display;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  color: #636d72;
}

.login-form >>> .el-input__inner {
  height: 72px;
  background-color: #f2f5f8;
  border: none;
  font-size: 20px !important;
  border-radius: 16px;
  /* color: pink !important; */
}

.login-form >>> .el-input .el-input__clear {
  font-size: 20px !important;
  /* color: pink; */
}

.btn {
  width: 100%;
  height: 72px;
  background-color: #ec1c24;
  border: none;
  font-size: 18px;
  /* font-family: Google Sans Display; */
  border-radius: 16px;
}

.login-form >>> input::-webkit-input-placeholder {
  /* padding-left: 42px; */
  font-size: 18px;
  color: #969b9e;
}
</style>
