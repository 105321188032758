export const m = {
  systemsettings: "系统设置",
  menumanagement: "菜单管理",
  rolemanagement: "角色管理",
  administratormanagement: "管理员管理",
  hospiatalmanagement: "医院管理",
  patientinformation: "病人信息",
  patientmanagement: "病人管理",
  option2: "选项2",
  option3: "选项3",
  othermanagement: "其他设置",
  option1: "选项1",
  homepage: "首页",
  welecom: "欢迎",
  add: "添加",
  name: "名称",
  icon: "图标",
  type: "类型",
  operation: "操作",
  catalogue: "目录",
  edit: "编辑",
  delete: "删除",
  view: "展示",
  superior: "上级",
  cataloguename: "目录名称",
  state: "状态",
  menu: "菜单",
  determination: "确定",
  reset: "重置",
  psaaa: "请选择",
  administrator: "管理员",
  doctor: "医生",
  enable: "启用",
  rolename: "角色名称",
  menupermissions: "菜单权限",
  userid: "用户ID",
  role: "角色",
  username: "用户名",
  examine: "查看",
  jobnumber: "工作编号",
  doctorname: "医生名称",
  password: "密码",
  confirmpasdsword: "确认密码",
  nurse: "护士",
  patient: "病人",
  patientid: "病人ID",
  patientidname: "病人名称",
  intreatment: "就诊中",
  personalinformation: "病人信息",
  familyname: "姓",
  firstname: "名字",
  middlename: "中名",
  gender: "性别",
  race: "民族",
  slo: "请选择",
  dob: "生日",
  pd: "物理数据",
  utms: "使用公制",
  utis: "使用帝国制",
  ft: "英尺",
  lb: "磅",
  kg: "公斤",
  meter: "米",
  continus: "下一步",
  previouspage: "上一页",
  pefn: "请输入姓",
  pefirstn: "请输入名字",
  pemn: "请输入中名",
  poccu: "请输入您的职业",
  female: "女性",
  male: "男性",
  africanamerican: "非裔美国人",
  asianamerican: "亚裔美国人",
  caucasian: "白种人",
  hispanic: "西班牙",
  others: "其他",
  hospitalinformation: "医院信息",
  hospitalnumber: "医院编号",
  hospitalname: "医院名字",
  doctorinformation: "医生信息",
  doctornumber: "医生编号",
  pethn: "请输入医院编号",
  pethname: "请输入医院名字",
  pedn: "请输入医生编号",
  petdname: "请输入医生名字",
  dychohbpdwaotad: "您目前或以前是否被诊断患有上述任何疾病?",
  diabetes: "糖尿病",
  neuropathy: "神经病",
  hypertension: "高血压",
  implantationofpacemaker: "植入起搏器",
  hepatopathy: "肝病",
  kidneydisease: "肾脏疾病",
  nicotineuse: "尼古丁使用",
  dialysistreatment: "透析治疗",
  novelcoronavirus: "2019新冠病毒",
  miocardialinfarction: "心肌梗塞",
  pvd: "外周血管疾病",
  ptcaos: "经皮腔内冠状动脉成形术或支架",
  cerebrovascularaccident: "脑血管意外",
  congestiveheartfailure: "充血性心衰",
  Coronaryarterybypassgrafting: "冠状动脉旁路移植术",
  listcurrentdrugs: "列出目前药物",
  otherhealthtestresults: "其他健康测试结果",
  supplement: "补充意见",
  reasonforvisit: "就诊原因",
  accountnumber: "账号",
  submit: "提交",
  globaladmin: "全球管理员角色",
  hospitaladmin: "医院管理角色",
  doctorrole: "医生角色",
  accountmanagement: "账号管理",
  globaladminaccount: "全局管理员账号",
  hospitaladminaccount: "医院管理账号",
  doctoraccount: "医生管理账号",
  devicemanagement: "设备管理",
  testresult: "测试结果",
  dpht: "显示患者病历对比",
  uef: "上传exta信息文件",
  historymedical: "病历",
  email: "电子邮件",
  ekts: "输入关键字进行搜索",
  deviceid: "设备ID",
  devicename: "设备名",
  portnumber: "端口号",
  switchaccount: "切换账号",
  exitlogin: "退出登录",
  uaf: "上传附件文件",
  clickupload: "点击上传",
  date: "测试日期",
  results: "结果",
  clicktoview: "点击查看",
  details: "详细资料",
  parametername: "参数名",
  parameterrange: "参数范围",
  parametervalue: "参数值",
  parameterdescription: "参数描述",
  parameterresults: "参数结果",
  address: "地址",
  dateofbirth: "出生日期",
  dateofbirth1: "请输入您的出生日期",
  hospitaladdress: "医院地址",
  petha: "请输入医院地址",
  viewdetails: "查看详情",
  down: "下载",
  downall: "下载全部",
  historytest: "历史测试",
  myaccount: "我的账号",
  aboutus: "关于我们",
  recentpatient: "最近病人",
  accountid: "全局ID",
  hospitalid: "医院ID",
  doctorid: "医生ID",
  morning: "早上好",
  payment: "支付",
  subscription: "订阅",
  linechart: "Line chart",
  Pemailadd: "请输入邮箱地址",
  switchcharts: "切换图表",
  Switchingnumber: "切换人数",
  next: "下一步",
  testmodule: "测试模块",
  occupation: "病人职业",
  edulevel: "病人教育水平",
  pcountry: "病人国家",
  pcity: "病人城市",
  pstate: "病人状态",
  pethic: "病因",
  paddres1: "病人住址1",
  paddres2: "病人住址2",
  ppostal: "病人邮政编码",
  phisid: "医学历史ID",
  bioid: "生物数据ID",
  all: "所有例子",
  upload: "上传",
  features: "特征",
  return: "返回",
};
