/****   request.js   ****/
// 导入axios
import axios from "axios";
import store from "@/store";
import ElementUI from "element-ui";
let baseURL = `${process.env.VUE_APP_JAVA_SERVERHOST}:${process.env.VUE_APP_JAVA_SERVERPORT}`; //index.html引入的
let baseURL2 = `${process.env.VUE_APP_PYTHON_SERVERHOST}:${process.env.VUE_APP_PYTHON_SERVERPORT}`;

const CATEGORY_TYPE = {
  success: 1,
  error: 2,
  info: 3,
  warning: 4,
};

//1. 创建新的axios实例，
const service = axios.create({
  // 超时时间 单位是ms，这里设置了20s的超时时间
  timeout: 30000,
});
// 2.请求拦截器
service.interceptors.request.use(
  (config) => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    config.headers = {
      "Content-Type": "application/json", //配置请求头
      // "access-control-allow-origin": "*",
    };

    config.headers.token = store.getters.token;
    switch (config.urlType) {
      case "api1":
        config.url = baseURL + config.url;
        break;
      case "api2":
        config.url = baseURL2 + config.url;
        break;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    // const h = this.$createElement;
    if (response.data.category == CATEGORY_TYPE.error) {
      ElementUI.Notification({
        message: response.data.msg,
        customClass: "notificationfont",
        duration: 3000,
      });
    } else if (response.data.category == CATEGORY_TYPE.success) {
      ElementUI.Notification.closeAll();
    }
    return response;
  },
  (error) => {
    /***** 接收到异常响应的处理开始 *****/
    if (error || error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      if (error.response.status == 401) {
        error.message = "Unauthorized, please log in again";
        window.location.href = "/login";
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        localStorage.removeItem("enmu");
      }
      // 4xx 5xx 服务器错误
      if (error.response.status.toString().startsWith("4") || error.response.status.toString().startsWith("5")) {
        error.message = error.response.data.msg;
      } else if (JSON.stringify(error).includes("Network Error")) {
        error.message = "Please check your network connection";
      } else if (JSON.stringify(error).includes("timeout")) {
        // 超时处理
        error.message = "Please check your network connection";
      }
    }
    ElementUI.Notification({
      message: error.message,
      customClass: "notificationfont",
      duration: 3000,
    });
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response);
  }
);
//4.导入文件
export default service;
