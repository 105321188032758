<template>
  <div class="header">
    <el-breadcrumb 
      separator="/" 
      class="bread-crumbs"
    >
      <el-breadcrumb-item
        v-for="(t,i) in showBreadcrumb"
        id="myColor"
        :key="i"
        :to="{ path: t.path }"
      >
        {{ t.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="dropdown-combo">
      <div class="dropdown-wrapper">
        <div 
          class="block" 
          style="margin-bottom:-2px;"
        >
          <img
            src="@/assets/img/Frame.png"
            alt
          >
        </div>
        <el-dropdown>
          <span class="el-dropdown-link">
            <span style="color:#333;font-size:15px;">English</span>
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class="xl dropdown"
          >
            <el-dropdown-item 
              icon="el-icon-user" 
              @click.native="showNotification"
            >
              Chinese
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class="dropdown-wrapper" 
        style="margin-left:40px;background:#fff; padding:0;"
      >
        <div
          class="block"
          style="margin-bottom:-1px;"
        >
          <el-avatar 
            shape="square" 
            size="small" 
            :src="squareUrl"
          />
        </div>
        <el-dropdown>
          <span class="el-dropdown-link">
            <span 
              style="color:#333;font-size:15px;"
            >
              {{ staffs.firstName }}
            </span>
            <i class="el-icon-arrow-down el-icon--right" />
          </span>

          <el-dropdown-menu
            slot="dropdown"
            class="xl dropdown"
          >
            <el-dropdown-item
              icon="el-icon-switch-button"
              @click.native="handleShowExitDialog"
            >
              Sign out
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-key"
              @click.native="handleShowChangePasswordDialog"
            >
              Change Password
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- Sign Out Dialog -->
    <el-dialog
      :visible.sync="deletes" 
      :show-close="false" 
      width="40%" 
      close-on-press-escape
    >
      <div class="dl-content-wrapper">
        <span>Would you like to sign out ?</span>
        <div>
          <el-button
            class="dialog-button"
            style="background:#EC1C24;color:#fff;"
            @click="handleConfirmExit"
          >
            Confirm
          </el-button>
          <el-button
            class="dialog-button" 
            @click="deletes=false"
          >
            Cancel
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- Change Password Dialog -->
    <el-dialog 
      :visible.sync="showPasswordDialog"
      :show-close="true"
      width="700px"
      close-on-press-escape
    >
      <div class="dl-content-wrapper">
        <span>Change Password</span>
        <el-form
          ref="ruleForm"
          status-icon
          :model="ruleForm"
          :rules="changePwdRules"
          label-width="150px" 
          :label-position="labelPosition"
        >
          <el-form-item 
            label="Original Password" 
            prop="currentPassword"
            class="el-form-item-wrapper mb-30"
            required
          >
            <el-input 
              v-model="ruleForm.currentPassword" 
              type="password" 
              autocomplete="off" 
              :show-password="true"
            />
          </el-form-item>
          <el-form-item
            label="New Password" 
            prop="newPassword" 
            style="margin-left: 0px;"
            status-icon="true"
            class="mb-30"
            required
          >
            <el-input 
              v-model="ruleForm.newPassword" 
              type="password" 
              autocomplete="off"
              :show-password="true"
            />
          </el-form-item>
          <el-form-item 
            label="Confirm Password" 
            prop="checkedPwd"
            class="mb-30"
            required
            @keyup.enter.native="submitForm('ruleForm')"
          >
            <el-input 
              v-model="ruleForm.checkedPwd" 
              type="password" 
              autocomplete="off"
              :show-password="true"
            />
          </el-form-item>
          <div>
            <el-button
              class="dialog-button"
              style="background:#EC1C24;color:#fff;"
              @click="submitForm('ruleForm')"
            >
              Submit
            </el-button>
            <el-button
              class="dialog-button"
              @click="resetForm('ruleForm')"
            >
              Reset
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import http from "../common/utils";
  export default {
    data() {
      return {
        labelPosition: "left",
        ruleForm: {
          currentPassword: "",
          newPassword: "",
          checkedPwd: ""
        },
        changePwdRules: {
          currentPassword: [
            { 
              required: true, 
              message: "Please enter the original password", 
              trigger: "blur" 
            }
          ],
          newPassword: [
            { 
              required: true, 
              validator: this.validateNewPass, 
              trigger: "blur" 
            }
          ],
          checkedPwd: [
            { 
              required: true, 
              validator: this.validateCheckedPass, 
              trigger: "blur" 
            }
          ]
        },
        levelList: [],
        staffs: {},
        deletes: false,
        showPasswordDialog: false,
        st: 2,
        squareUrl:
          "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
        showBreadcrumb: [{
          title: "Home Page",
          path: "/index" 
        }]
      };
    },

    watch: {
      $route() {
        this.setBreadcrumb();
      }
    },

    created() {
      this.setBreadcrumb();
    },

    methods: {
      // minimum 1 uppercase character
      // minimum 1 lowercase character
      // minimum 1 digit
      // old password and new password should not be the same
      validateNewPass(rule, value, callback) {
        const upperCasePattern = /[A-Z]/;
        const lowerCasePattern = /[a-z]/;
        const digitPattern = /\d/;

        if (value === "") {
          callback(new Error("Please enter the new password"));
        } else if (this.ruleForm.currentPassword === value) {
          callback(new Error("New password cannot be the same as the original password"));
        } else if (!upperCasePattern.test(value)) {
          callback(new Error("The password must contain at least one uppercase letter"));
        } else if (!lowerCasePattern.test(value)) {
          callback(new Error("The password must contain at least one lowercase letter"));
        } else if (!digitPattern.test(value)) {
          callback(new Error("The password must contain at least one digit"));
        } else {
          callback();
        }
      },

      validateCheckedPass(rule, value, callback) {
        if (value === "") {
          callback(new Error("Please enter the confirm password"));
        } else if (value !== this.ruleForm.newPassword) {
          callback(new Error("The two passwords do not match"));
        } else {
          callback();
        }
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const data = {
              currentPassword: this.ruleForm.currentPassword,
              newPassword: this.ruleForm.newPassword,
            }
            http.put("/v1/updatePassword", data, "api1").then(result => {
              if (result.data.code === 200){
                this.resetForm("ruleForm");
                this.showPasswordDialog = false;
                this.handleConfirmExit();
              }
            }).catch((err) => {
              throw err;
            });
          } else {
            return false;
          }
        });
      },

      resetForm(formName) {
        this.$refs[formName].resetFields();
      },

      showNotification() {
        this.$notify({
          message: "This feature is not available yet.",
          duration: this.$globaltime,
          showClose: false
        });
      },

      handleShowExitDialog() {
        this.deletes = true;
      },

      handleShowChangePasswordDialog() {
        this.showPasswordDialog = true;
      },

      handleClosePasswordDialog() {
        this.showPasswordDialog = false;
      },

      handleConfirmExit() {
        this.deletes = false;
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        localStorage.removeItem("enmu");
        localStorage.removeItem("menulist");
        localStorage.removeItem("examId");
        this.$router.push("/login");
      },

      setBreadcrumb() {
        this.showBreadcrumb = [{
          title: "Home Page",
          path: "/index" 
        }];
        let arr = this.$router.getRoutes();
        this.$route.name.split(".").forEach(t => {
          let obj = arr.find(v => v.path == "/" + t);
          obj &&
            this.showBreadcrumb.push({
              title: obj.meta.title,
              path: obj.path
            });
        });
      }
    }
  };
</script>

<style scoped src="./HeaderView.css" />

<!-- these styled need to put in the current file -->
<style>
  .el-breadcrumb__inner a,
  .el-breadcrumb__inner.is-link {
    font-weight: 400 !important;
    color: #969b9e !important;
  }
  .dropdown {
  /* width: 160px; */
  text-align: left;
}
.btn1 {
  /* height: 40px; */
  /* padding: 20px 30px; */
  border: 1px solid #ec1c24;
  background-color: #fff;
  color: #ec1c24;
  border-radius: 8px !important;

  font-size: 16px;
  /* margin: 0 8px; */
}
.btn1:hover {
  color: white !important;
  border: 1px solid #f5a9a8 !important;
  background-color: #f5a9a8 !important;
}
/*  */
/* .lang-select-dropdown {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
} */
.mbx {
  /* float: left; */
  margin-left: 20px;
  /* line-height: 50px !important; */
  color: #333 !important;
  font-size: 16px !important;
}
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
  color: #969b9e !important;
}
.h2-2 {
  width: 30px;
  height: 30px;
  border: none !important;
  background: none !important;
  margin-top: 5px;
  display: block;
  margin-top: 12px;
}
.h2-2 img {
  max-width: 16px;
}
.el-dropdown-menu {
  position: static;
  margin-top: -30px;
}
.btn-back {
  color: #333 !important;
  background-color: white !important;
  border: 1px solid #999 !important;
}
.btn-back:hover {
  color: white !important;
  border: 1px solid #eb5350 !important;
  background-color: #eb5350 !important;
}
.btn-back:click {
  color: white !important;
  border: 1px solid #eb5350 !important;
  background-color: #eb5350 !important;
}
.block {
  margin: 2px 5px 2px 5px;
}
.heade {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.zy {
  display: flex;
  align-items: center;
  /* width: 150px; */
  background: rgb(242, 245, 248);
  /* height: 40px; */
  size: 16px;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px;
}

.heade >>> .el-breadcrumb__inner.is-link {
  font-weight: normal;
}
/* .heade >>> .el-breadcrumb__inner is-link{
  color: #333 !important;
} */

.heade >>> .el-avatar--large {
  width: 30px !important;
  height: 30px !important;
}
.xl {
  width: 200px;
  text-align: left;
}

/* 
.simu-dropdown {
  
} */
.results1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.results1 p {
  font-size: 24px;
}
</style>