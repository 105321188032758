<template>
  <div>
    <div id="app">
      <!-- <router-view /> -->

      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
  </div>
</template>

<script>
  export default {
    name: "App",

    data() {
      return {};
    }
  };
</script>

<style src="./assets/global.css" />
