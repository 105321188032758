import http from "../common/utils";
// let model = '/api/model/';
export function Login(data) {
  return http.post("/v1/login", data, "api1");
}
export function enmu() {
  return http.get("/v1/practicegroup", {}, "api1");
}

export function staff(data) {
  return http.get("/v1/staff/" + data.staffId, {}, "api1");
}
